import React from "react"
import SEO from "../components/seo"
import TopBanner from "../components/common/top-banner/top-banner"
import { graphql } from "gatsby"
import ExtendedContent from "../components/extendedContent"
import { setTopColors, toggleSubmenu } from "../state/app"
import { connect } from "react-redux"
import { saveUrlsToLocalStorage } from "../components/common/type-to-url-match/type-to-url-match"

class ContactPage extends React.Component {

  componentDidMount() {
    const { dispatch, pageContext } = this.props
    const topColors = this.props.data.entries?.nodes?.[0]?.archeeTopKolory || ""
    dispatch(setTopColors(topColors))
    dispatch(toggleSubmenu(false))
    saveUrlsToLocalStorage(pageContext)
  }

  render() {
    const { data } = this.props
    const { categories } = data;
    const entries = data.entries?.nodes?.[0] || "";
    return (
      <>
        <TopBanner banner={entries.archeeBaner} map={{ lat: 20, lng: 20 }} topColors={entries.archeeTopKolory}/>
        <ExtendedContent data={entries.archeeTrescPodstrony} categories={categories.nodes || []}/>
      </>
    )
  }
}

export default connect(state => ({
  topColors: state.app.topColors,
  isSubmenuVisible: state.app.isSubmenuVisible,
}), null)(ContactPage)


export const query = graphql`
    query($remoteId: ID, $langKey: String)  {
            seo: allCraftArcheeKontaktArcheeKontaktEntry(filter: {remoteId: {eq: $remoteId}, language: {eq: $langKey}}) {
                nodes{
                    ...seoContact
                }
            }
            categories: allCraftArcheeRodzajRozmowyCategory(filter: {language: {eq: $langKey}}) {
                nodes {
                    title
                }
            }
            entries: allCraftArcheeKontaktArcheeKontaktEntry(filter: {remoteId: {eq: $remoteId}, language: {eq: $langKey}}){
                nodes {
                    title,
                    archeeTopKolory,
                    archeeBaner {
                        ...banner,
                    }
                    archeeTrescPodstrony {
                        __typename
                        ...margines,
                        ...naglowek,
                        ...naglowekIOpis,
                        #                    ...component,
                        ...filmLink,
                        ...przycisk
                        ...modulyGraficzne
                    }
                    seoTitle
                }
            }
    }`
export function Head({ data }) {
    const seoData = data?.seo?.nodes?.[0] || [];
    return (
        <SEO seoData={seoData}/>
    )
}